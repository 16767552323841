import React from "react";
import Seo from "./../components/Seo";
import { Section, Title, Text, MainTitle, Button } from "./../components/Core";
import { Container, Row, Col } from "react-bootstrap";
import HeroSocialTariff from "../components/Rebrand/Hero/HeroSocialTariff";
import socialTariff from './../assets/image/rebrand/png/social-tariff.png'
import ChooseYou from "../components/Rebrand/ChooseYou/ChooseYou";
import CheckAvailability from "../components/Rebrand/CheckAvailability/CheckAvailability";

const SocialTariff = () => {

    return (
        <div className="socialtariff">
            <Seo page="socialtariff" />
            <HeroSocialTariff />
            <Section>
                <Container>
                    <Row>
                        <Col sm={12} md={4} lg={4}>
                            <h3 className="mt-2 mb-5">
                                “I want everybody to benefit from a truly fast & affordable internet service”
                            </h3>
                            <p>
                                — JEREMY CHELOT. FOUNDER & CEO.
                            </p>
                        </Col>
                        <Col sm={12} md={8} lg={4}>
                            <p className="mb-3">
                                We believe ultrafast and ultra-affordable broadband should be available to everyone,
                                which is exactly why we’ve introduced the YouFibre Social Tariff to support low income households.
                            </p>
                            <p className="mb-5">
                                The same incredible YouFibre broadband product and friendly UK-based customer service,
                                but available at a discounted rate for those in receipt of qualifying state benefits.​*
                            </p>
                            <p className="small-font">
                                *Evidence of receiving qualifying state benefits will be required before an order can be confirmed. 24 month contract applies, however if benefits circumstances change then you must provide us with 30 days’ notice and no early termination fees will apply. Any add-ons will be chargeable at full price. Cannot be combined with any other offer. Our social tariff is available to new and existing customers who receive any of the following benefits: Income Support, Pension Credit, Income-related Job Seekers Allowance, Housing Benefit, Personal Independence Payment, Attendance Allowance, Universal Credit. Average speeds shown are achievable by 50% of users.
                            </p>
                        </Col>
                        <Col sm={{ span: 8, offset: 2 }} md={{ span: 8, offset: 4 }} lg={{ span: 4, offset: 0 }} className="text-center">
                            <img src={socialTariff} width="70%" className="mt-4 mb-5"/>
                            <a href="tel:03334439694">
                                <Button className="medium-button mb-5 blue-bg">Call 0333 443 9694 to apply</Button>
                            </a><br />
                            <Button onClick={() => (typeof window !==  "undefined") && document.getElementsByClassName('YFChat')[0].click()} className="medium-button blue-bg mt-2">
                                Chat&nbsp;Now
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Section>
            <ChooseYou />
            <CheckAvailability center title={'Are we right up your street?'} />
        </div>
    );
};
export default SocialTariff;


import React from "react";
import { Container, Col, Row } from "react-bootstrap";

const HeroSocialTariff = (props) => {
    return (
        <div className="dark-bg hero-section">
            <Container>
                <Row>
                    <Col sm={12} className="mb-80 mt-80">
                        <h1>Social<br />tariff</h1>
                    </Col>
                </Row>
            </Container>
        </div>
    )
};

export default HeroSocialTariff;
